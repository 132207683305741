.marquee-container {
    overflow: hidden;
    white-space: nowrap;
}

.image-marquee {
    display: inline-flex;
    animation: marquee 60s linear infinite;
}

@keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}

.marquee-container.reverse .image-marquee {
    animation-direction: reverse; /* Inverte a direção da animação */
}

.carousel-image {
    flex-shrink: 0;
    width: 110px;
    padding: 5px;
}
