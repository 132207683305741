.cookies-message {
  z-index: 999!important;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.cookies-message p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.cookies-message a {
  color: #007bff;
  text-decoration: underline;
}

.cookies-message button {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cookies-message button:hover {
  background-color: #0056b3;
}
