.ant-float-btn-body {
    background-color: #00db6c !important;
    height: 60px !important;
    width: 60px !important;
}
.ant-float-btn-icon {
    font-size: 20px !important;
}

.custom-alert-no-icon .ant-alert-icon {
    display: none;
}

.h1-site {
    font-size: clamp(5rem, calc(2.73214rem + 4.28571vw), 4.875rem);
    line-height: .86;
    letter-spacing: 0;
    color: #1a252f;
    font-weight: 900;
}

.site-lead {
    font-size: clamp(1.425rem, calc(.58114rem + .87719vw), 1.55rem);
    line-height: 28px;
    letter-spacing: .019em;
    color: #1a252f;
}

@media (max-width:575.98px){
    .h1-site {
        line-height: .85;
        letter-spacing: 0;
        font-weight: 900;
        font-size: clamp(0.625rem,calc(.07955rem + 12.72727vw), 2.25rem);
    }

    .site-lead {
        line-height: 26px;
        letter-spacing: .005em;
        font-size: clamp(1rem,calc(.63636rem + 1.81818vw),1.125rem);
    }
}

.h2-site {
    font-size: 60px;
}

.offer-description {
    font-size: 0.9rem;
    margin-right: 1rem;
}


.oggo-footer {
    font-size: 0.75rem;
}

.oggo-footer .footer-info {
    font-size: 0.85rem;
}

.oggo-footer .trademark-area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px
}

#howItWorks .step-item { cursor: pointer }

.oggo-footer .trademark-area .mr-30{
    margin-left: 6rem;
    margin-right: 6rem;
}

.oggo-footer .list-unstyled {
    margin-top: 18px;
}

.oggo-footer .item-mt-1 li {
    margin-top: 8px;
}

.start-free-trial {
    background: #e60078FF;
    color: #fff;
}

.start-free-trial:hover {
    background: #ec479d;
    color: #fff;
}


.oggo-text {
    color: #e60078FF;
}



@keyframes scroll {
    0% {
        height: 30px;
    }
    30% {
        height: 60px;
    }
    60% {
        height: 30px;
    }
}

@-webkit-keyframes scroll {
    0% {
        height: 30px;
    }
    30% {
        height: 60px;
    }
    60% {
        height: 30px;
    }
}


.scroll {
    width: 42px;
    height: 42px;
    border: 1px solid #333;
    border-radius: 50%;
    position: relative;
    animation: down 1.5s infinite;
    -webkit-animation: down 1.5s infinite;
}

.scroll::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 13px;
    width: 13px;
    height: 13px;
    border-left: 2px solid #333;
    border-bottom: 2px solid #333;
    transform: rotate(-45deg);
}

@keyframes down {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateY(15px);
    }
    40% {
        transform: translate(0);
    }
}

@-webkit-keyframes down {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateY(15px);
    }
    40% {
        transform: translate(0);
    }
}